function Nine() {
    return (
        <div>
            <div className="pl-2 pr-2">
                <div className="collapse collapse-arrow bg-base-100 rounded-lg border-solid border-2 border-gray-400">
                    <input type="radio" name="my-accordion-1" />
                    <div className="collapse-title text-xl font-bold">
                        📡 광고
                    </div>
                    <div className="collapse-content">
                        <p align="middle">
                            <img className="rounded-lg" width={500} src="https://month9.kro.kr/9/comic.png" alt="comic" />
                        </p>
                    </div>
                </div>
            </div>
            <div className="pl-2 pr-2 pt-2">
                <div className="collapse collapse-arrow bg-base-100 rounded-lg border-solid border-2 border-gray-400">
                    <input type="radio" name="my-accordion-1" />
                    <div className="collapse-title text-xl font-bold">
                        ☀ 9반 여름날의 모습
                    </div>
                    <div className="collapse-content">
                        <a href="https://file.kiwi/ac647876#JtSoOieWhx3MO1GzXeUEFg" target="_blank" rel="noreferrer">
                            <button className="btn btn-white text-lg md:text-2xl mb-2">
                                9월호 미디어 다운로드 (1.4GB)
                            </button>
                        </a>
                        <br />
                        <span style={{ color: "red" }}>파일의 크기가 너무 크고 파일이 너무 많아 압축 파일로 대체합니다.</span>
                        <br />
                        <span>다운로드 방법을 모르신다면 <a href="https://ko335.channel.io" target="_blank" rel="noreferrer" className="text-blue-900 hover:text-blue-600 font-bold">여기</a>로 문의해 주세요.</span>
                        <br />
                        <span>MD5 file checksum: e718598e4da35b2c97a7791067343269</span>
                    </div>
                </div>
            </div>
            <div className="p-2">
                <div className="p-4 bg-base-100 rounded-lg border-solid border-2 border-gray-400">
                    디자인/편집:강현호&nbsp;&nbsp;광고/만화:김가연&nbsp;&nbsp;인터뷰:박소윤&nbsp;&nbsp;기획 기사:홍지유&nbsp;&nbsp;사설/논설:황도경
                </div>
            </div>
        </div >
    );
}

export default Nine;
