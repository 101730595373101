import { Link } from 'react-router-dom';

function NotFound() {
  return (
    <div className="mr-2 ml-2 rounded-lg border-solid border-2 border-gray-400">
      <p align="middle">
        <br />
        <br />
        <span className="text-5xl md:text-8xl font-bold">404 Page Not Found</span>
        <br />
        <br />
        <span className="text-xl md:text-5xl font-bold">만일 이 페이지가 지속적으로 표시된다면 여기로 문의해주세요.</span>
        <br />
        <br />
        <Link to="/">
          <button className="btn btn-white text-2xl md:ml-2 mt-2">홈으로 돌아가기</button>
        </Link>
        <br />
        <br />
      </p>
    </div>
  );
}

export default NotFound;
